import React from "react"

import { Sources } from "@elements/index"
import { rules } from "@styles/mq"
import theme from "@styles/theme"
import * as Yup from "yup"

import {
  BannerImageContainer,
  BannerImageSegment,
  BannerHeading,
  BannerImage,
  BannerButtonContainer,
} from "./SingupBanner.styles"

import { KpButton } from "@elements/index"
import { Grid, Container, Form } from "semantic-ui-react"
import {
  FormikFieldEmailValidationInput,
  KpFormField,
  KpFormInputErrorMessage,
} from "@styles/Global.forms"
import { ButtonType } from "@utils/KontentTypes"
import { SubscriptionService } from "@services/index"
import { Formik } from "formik"
import { Paragraph1 } from "@styles/Global.styles"
import { EmailValidationService } from "@services/EmailValidationService"
import { InlineEmailValidatingLoader } from "@components/PersonalDetailsForm/PersonalDetailsForm.styles"
import { EmailRegex, NameRegex } from "@utils/Constant"

/* eslint-disable jsx-a11y/label-has-associated-control */

interface SignupProps {
  apiUrl: string
  emailValidationToken: string
  title: string
  button: ButtonType
  image: {
    url: string
    description: string | undefined
  }
}

interface SignupState {
  currentEmail: string
  validatingEmail: boolean
  emailIsValid?: boolean
}
class SignupBannerLarge extends React.Component<SignupProps, SignupState> {
  constructor(props: SignupProps) {
    super(props)
    this.state = {
      currentEmail: "",
      validatingEmail: false,
      emailIsValid: undefined,
    }
  }
  validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required").matches(NameRegex, "Enter valid characters"),
    lastName: Yup.string().required("Last Name is required").matches(NameRegex, "Enter valid characters"),

    email: Yup.string()
      .required("Email is required")
      .matches(EmailRegex, "Must be a valid email address"),
  })
  intialStatus = {
    isSubmitting: false,
    isSubmitted: false,
  }

  insitialValues = {
    firstName: "",
    lastName: "",
    email: "",
  }
  validateEmailAddress = (value: any) => {
    if (this.state.currentEmail !== value) {
      this.setState({
        currentEmail: value,
        validatingEmail: true,
        emailIsValid: undefined,
      })
      let error
      return EmailValidationService.validate(
        this.props.emailValidationToken,
        value,
        false
      ).then((result: any) => {
        if (result.success == true) {
          this.setState({ validatingEmail: false, emailIsValid: true })
        } else {
          error = "Invalid Email"
          this.setState({ validatingEmail: false, emailIsValid: false })
          return error
        }
      })
    } else if (this.state.emailIsValid == false) {
      return "Invalid Email"
    }
  }

  submitForm = (values: any, actions: any) => {
    if (!this.state.validatingEmail) {
      actions.setStatus({
        isSubmitting: true,
        isSubmitted: false,
      })

      SubscriptionService.subscribeUserRequest(
        this.props.apiUrl,
        {
          firstname: values.firstName,
          lastname: values.lastName,
          email: values.email,
        },
        "Website Banner",
        {
          campaignName: 'KiwiVIP'
        }, false
      ).then(data => {
        actions.setStatus({
          isSubmitting: false,
          isSubmitted: true,
        })
      })
    } else {
      actions.setSubmitting(false)
    }
  }

  render() {
    const { title, image, button } = this.props
    const { url, description } = image
    const lowSrc = `${url}?w=100&auto=format`

    return (
      <BannerImageSegment>
        <BannerImageContainer>
          <BannerImage
            className="banner"
            description={description}
            fallback={`${url}?w=768&auto=format 1x, ${url}?w=1536&auto=format 2x`}
            lowSrc={lowSrc}
            sources={Sources(url, rules, theme.imageBreakpoints)}
          />
        </BannerImageContainer>
        <Container>
          <Formik
            initialValues={this.insitialValues}
            validationSchema={this.validationSchema}
            initialStatus={this.intialStatus}
            validateOnChange={false}
            onSubmit={(values: any, actions: any) =>
              this.submitForm(values, actions)
            }
          >
            {props => (
              <Form inverted onSubmit={props.handleSubmit}>
                <Grid verticalAlign="top" columns={1} centered>
                  <Grid.Column
                    mobile={16}
                    tablet={12}
                    computer={12}
                    largeScreen={8}
                    widescreen={8}
                    textAlign="center"
                  >
                    <div>
                      <BannerHeading>{title}</BannerHeading>
                      {!props.status.isSubmitted && (
                        <div>
                          <Form.Group widths="equal">
                            <KpFormField
                              error={
                                props.errors.firstName &&
                                props.touched.firstName
                              }
                            >
                              <label>First Name</label>
                              {props.errors.firstName &&
                                props.touched.firstName && (
                                  <KpFormInputErrorMessage>
                                    {props.errors.firstName}
                                  </KpFormInputErrorMessage>
                                )}
                              <input
                                placeholder="First Name"
                                id="firstName"
                                name="firstName"
                                data-private="lipsum"
                                type="text"
                                aria-label="First Name"
                                disabled={props.status.isSubmitting}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.firstName}
                              ></input>
                            </KpFormField>
                            <KpFormField
                              error={
                                props.errors.lastName && props.touched.lastName
                              }
                            >
                              <label>Last Name</label>
                              {props.errors.lastName &&
                                props.touched.lastName && (
                                  <KpFormInputErrorMessage>
                                    {props.errors.lastName}
                                  </KpFormInputErrorMessage>
                                )}
                              <input
                                placeholder="Last Name"
                                id="lastName"
                                name="lastName"
                                type="text"
                                aria-label="Last Name"
                                data-private="lipsum"
                                disabled={props.status.isSubmitting}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.lastName}
                              ></input>
                            </KpFormField>
                          </Form.Group>
                          <KpFormField
                            error={props.errors.email && props.touched.email}
                          >
                            <label>Email</label>
                            {props.errors.email && props.touched.email && (
                              <KpFormInputErrorMessage>
                                {props.errors.email}
                              </KpFormInputErrorMessage>
                            )}
                            <FormikFieldEmailValidationInput
                              placeholder="Email"
                              width={16}
                              id="email"
                              name="email"
                              data-private="lipsum"
                              type="email"
                              aria-label="Email Address"
                              disabled={props.status.isSubmitting}
                              onChange={props.handleChange}
                              inputValid={this.state.emailIsValid}
                              validate={this.validateEmailAddress}
                              onBlur={props.handleBlur}
                              value={props.values.email}
                            />
                            {/*
                                    {
                                        this.state.validatingEmail &&
                                        <SignupEmailValidatingLoader active inline size="small" />
                                    } */}
                            <InlineEmailValidatingLoader
                              active={this.state.validatingEmail}
                              inline
                              size="small"
                            />
                          </KpFormField>
                          <BannerButtonContainer>
                            <KpButton
                              id="sblsmt"
                              type="submit"
                              loading={props.status.isSubmitting}
                              buttonType={button.type}
                              color={button.theme}
                              disabled={!(props.isValid && props.dirty)}
                            >
                              {button.text}
                            </KpButton>
                          </BannerButtonContainer>
                        </div>
                      )}
                      {props.status.isSubmitted && (
                        <div>
                          <Paragraph1 color={theme.brand.colors.white}>
                            Thanks for subscribing!
                          </Paragraph1>
                        </div>
                      )}
                    </div>
                  </Grid.Column>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </BannerImageSegment>
    )
  }
}

export default SignupBannerLarge
